import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Button, Grid } from "@mui/material";
import React from "react";
import Config, { IConfig } from "../types/Config";
import SignUpSignInComponent from "./SignUpSignInComponent";

const MyInfoComponent = () => {
  const { instance } = useMsal();
  const config = React.useContext<IConfig>(Config);
  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };
  const handleChangePasswordRedirect = () => {
    instance.loginRedirect({
      scopes: [],
      authority: config.b2cPolicies.authorities.passwordChange.authority,
    });
  };
  return (
    <>
      <UnauthenticatedTemplate>
        <SignUpSignInComponent />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <Grid container spacing={0} direction="column">
          <Grid item mobile={12} laptop={12} className="page-header">
            My Info
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              mobile={12}
              laptop={12}
            >
              <Button
                variant="outlined"
                className="change-password"
                onClick={handleChangePasswordRedirect}
              >
                Change Password
              </Button>
              <br />
              <Button
                variant="outlined"
                className="record-symptoms"
                onClick={handleLogoutRedirect}
              >
                Log Out
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </>
  );
};

export default MyInfoComponent;
