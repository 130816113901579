import { Button } from "@mui/material";
import React from "react";
import BIcon from "./Icons/IconComponent";
import { ICONS } from "./Icons/IconComponentMapping";
import { useLocation, useNavigate } from "react-router";

type MessageStateContent = {
  type: string;
  messageText: string;
};
const MessageComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { messageText, type } = location.state as MessageStateContent;

  if (!type) return <>Something is not right!</>;
  return (
    <div>
      <div className="home-record-symptoms">
        <h1
          className={`message-header ${type === "success" ? "success" : "failure"}`}
        >
          {type === "success" ? "Success!" : "Failure!"}
        </h1>
        <div className={`${type === "success" ? "text-info" : "text-error"}`}>
          {messageText}
        </div>
      </div>
      <div style={{ margin: "50px 0px 50px 0px" }}>
        {/* Space for image based on the type of message */}
        <BIcon
          icon={
            type === "success" ? ICONS["IconSuccess"] : ICONS["IconFailure"]
          }
          style={{
            verticalAlign: "bottom",
            height: "200px",
            minWidth: "100%",
            width: "100%",
            fill: "none",
          }}
        />
      </div>
      <div className="home-record-symptoms">
        {/* Space for action for the message */}
        <Button
          variant="outlined"
          className="record-symptoms"
          onClick={() => {
            navigate("/");
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default MessageComponent;
