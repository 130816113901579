import { Button, CircularProgress, Grid } from "@mui/material";
import React from "react";
import BIcon from "./Icons/IconComponent";
import { ICONS } from "./Icons/IconComponentMapping";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Q_DEVICE_STATUS } from "../data/queries/device";
import DeviceDetails, { IDeviceDetails } from "../types/DeviceDetails";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import messages from "./../data/UserMessages.json";
import SignUpSignInComponent from "./SignUpSignInComponent";

enum enumHomeState {
  "ACTIVE_MONITORING" = "ACTIVE_MONITORING",
  "NO_TRANSMISSION" = "NO_TRANSMISSION",
  "NO_ACTIVE_DEVICE" = "NO_ACTIVE_DEVICE",
  "RETURN_DEVICE" = "RETURN_DEVICE",
  "SWITCH_PATCH" = "SWITCH_PATCH",
}
const HomeComponent = () => {
  const device = React.useContext<IDeviceDetails>(DeviceDetails);
  const [deviceData, setDeviceData] = React.useState(null);
  const [currentHomeState, setCurrentHomeState] = React.useState(null);
  const [getDeviceStatus, { data, loading, error }] =
    useMutation(Q_DEVICE_STATUS);
  React.useEffect(() => {
    if (device?.deviceId) {
      getDeviceStatus({
        variables: { deviceId: device?.deviceId },
      }).then(data => {
        setDeviceData(data?.data?.getDeviceStatus);
        getcurrentHomeState(data?.data?.getDeviceStatus);
      });
    }
  }, [device]);
  const navigate = useNavigate();
  const getcurrentHomeState = data => {
    let currentState: enumHomeState;
    const { isBatteryLevelCritical, isDataTransmissionCritical } = data;
    if (!isBatteryLevelCritical && !isDataTransmissionCritical) {
      currentState = enumHomeState.ACTIVE_MONITORING;
    } else if (isBatteryLevelCritical) {
      currentState = enumHomeState.SWITCH_PATCH;
    } else if (isDataTransmissionCritical) {
      currentState = enumHomeState.NO_TRANSMISSION;
    }
    setCurrentHomeState(currentState);
  };
  if (error) return <>Error!</>;
  return (
    <>
      <UnauthenticatedTemplate>
        <SignUpSignInComponent />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        {loading ? (
          <>
            <div className="page-header"></div>
            <div
              style={{
                color: "#0C80A1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          </>
        ) : (
          <Grid
            container
            spacing={0}
            direction="column"
            style={{
              minHeight: "inherit",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item mobile={12} laptop={6} style={{ background: "white" }}>
              <Grid container style={{ maxWidth: "600px", display: "flex" }}>
                <Grid item mobile={12} laptop={12} className="page-header">
                  {messages.home.txtHomeHeader} {device?.firstName}!
                </Grid>
                <Grid
                  item
                  mobile={12}
                  laptop={12}
                  className="home-welcome-desc"
                >
                  <div>
                    {messages.home[currentHomeState]?.txtHomeHeaderDesc}
                  </div>
                </Grid>
                <Grid
                  item
                  mobile={12}
                  laptop={12}
                  style={{ marginTop: "20px", display: "flex" }}
                  sx={{
                    paddingLeft: { mobile: "0px", laptop: "20px" },
                    justifyContent: {
                      mobile:
                        currentHomeState === "NO_TRANSMISSION" ||
                        currentHomeState === "SWITCH_PATCH"
                          ? "center"
                          : "inherit",
                      laptop: "inherit",
                    },
                  }}
                >
                  {currentHomeState in enumHomeState ? (
                    <BIcon
                      icon={ICONS[currentHomeState]}
                      style={{
                        verticalAlign: "bottom",
                        width: "auto",
                        height: "200px",
                      }}
                    />
                  ) : (
                    <>No Image</>
                  )}
                </Grid>
                <Grid
                  item
                  mobile={12}
                  laptop={12}
                  style={{ display: "flex", marginTop: "60px" }}
                  sx={{
                    paddingLeft: { mobile: "0px", laptop: "20px" },
                    justifyContent: { mobile: "center", laptop: "inherit" },
                  }}
                >
                  <Button
                    variant="outlined"
                    className="record-symptoms"
                    onClick={() => {
                      navigate("/patientsymptoms");
                    }}
                  >
                    {messages.home.btnRecordSymptoms}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </AuthenticatedTemplate>
    </>
  );
};

export default HomeComponent;
