import React from "react";
import { NavLink } from "react-router-dom";
import BIcon from "./Icons/IconComponent";
import { ICONS } from "./Icons/IconComponentMapping";
import { generateID } from "./utilityFunctions";
import { Typography } from "@mui/material";

export const navbarList = [
  { link: "/", iconComponent: "IconHomeFilled", label: "Home", id: "home" },
  {
    link: "/history",
    iconComponent: "IconSymptomsHistoryFilled",
    label: "History",
    id: "history",
  },
  {
    link: "/myinfo",
    iconComponent: "IconMyInfoFilled",
    label: "My Info",
    id: "my-info",
  },
  {
    link: "/learn",
    iconComponent: "IconLearnFilled",
    label: "Learn",
    id: "learn",
  },
];

const Navbar = () => {
  return (
    <div className="footer overall-background">
      <ul>
        {navbarList.map((navbarItem, navbarItemIndex) => {
          return (
            <NavLink to={navbarItem.link} key={navbarItemIndex}>
              {({ isActive }) => (
                <li id={generateID("li", "navbar", navbarItem.id)}>
                  <div
                    className={isActive ? "navbar-segment icon-container" : ""}
                  >
                    <BIcon
                      class="navigation-icon"
                      fillColor={isActive ? "#FFFFFF" : "#484C4E"}
                      icon={ICONS[navbarItem.iconComponent]}
                    />
                  </div>
                  <Typography
                    id={generateID("txt", "navbar", navbarItem.id + "-label")}
                    style={{ marginTop: "4px" }}
                    className={`common-navbar-icon-text ${isActive ? "active-navbar-icon-text" : "inactive-navbar-icon-text"}`}
                  >
                    {" "}
                    {navbarItem.label}{" "}
                  </Typography>
                </li>
              )}
            </NavLink>
          );
        })}
      </ul>
    </div>
  );
};

export default Navbar;
