import Config from "../types/Config";
import React from "react";
import { ApolloProvider } from "@apollo/client";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { ThemeProvider } from "@emotion/react";
import { Paper } from "@mui/material";

import { Client } from "../data/Client";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import RouteComponent from "./routes/RouteComponent";

function App(props: any) {
  const { instance } = useMsal();
  const client = Client.getInstance(props.config.apiEndpointUrl);
  return (
    <Config.Provider value={props.config}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={props.theme}>
          <MsalProvider instance={props.instance}>
            <Paper sx={{ display: { mobile: "none", laptop: "block" } }}>
              <Sidebar></Sidebar>
            </Paper>

            <Paper sx={{ display: { mobile: "block", laptop: "none" } }}>
              <Navbar></Navbar>
            </Paper>

            <Paper
              style={{ boxShadow: "none" }}
              sx={{
                marginLeft: { mobile: "0px", laptop: "280px" },
                marginTop: { mobile: "0px", laptop: "10px" },
                marginBottom: { mobile: "80px", laptop: "auto" },
                marginRight: { mobile: "0px", laptop: "40px" },
                height: {
                  mobile: "calc(100vh - 100px)",
                  laptop: "calc(100vh - 100px)",
                },
                position: { mobile: "relative", laptop: "fixed" },
                width: { mobile: "100%", laptop: "calc(100vw - 320px)" },
                overflowY: { mobile: "auto", laptop: "auto" },
                background: { mobile: "inherit", laptop: "scroll" },
                overflowX: { mobile: "hidden", laptop: "hidden" },
              }}
            >
              <RouteComponent />
            </Paper>
          </MsalProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Config.Provider>
  );
}

export default App;
