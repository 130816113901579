import { createTheme } from "@mui/material/styles";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { getMsalConfig } from "./data/authConfig";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.css";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    laptop: true;
  }
}
// define breakpoints for creating customized responsive design
const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      laptop: 830,
    },
  },
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
});

function parseFetchConfigResponse(text: string) {
  let obj;
  try {
    obj = JSON.parse(text);
  } catch (e) {
    alert(`Error occurred in reading application configuration.\n${e}`);
  }
  return obj;
}

fetch("/config/dev.json")
  .then(response => response.text())
  .then(text => parseFetchConfigResponse(text))
  .then(config => {
    const msalInstance = new PublicClientApplication(
      getMsalConfig(config.b2cPolicies)
    );

    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    msalInstance.addEventCallback(event => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload
      ) {
        msalInstance.setActiveAccount(event.payload["account"]);
      }
    });

    const root = createRoot(document.getElementById("root") as HTMLElement);
    root.render(
      <React.StrictMode>
        <Router>
          <App config={config} theme={theme} instance={msalInstance} />
        </Router>
      </React.StrictMode>
    );
  });

reportWebVitals();
