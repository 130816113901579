import { Route, Routes } from "react-router-dom";
import HomeComponent from "../HomeComponent";
import LearnComponent from "../LearnComponent";
import Main from "../Main";
import MessageComponent from "../MessageComponent";
import MyInfoComponent from "../MyInfoComponent";
import PatientSymptoms from "../PatientSymptoms";
import PatientSymptomsHistoryComponent from "../PatientSymptomsHistoryComponent";
const RouteComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route index element={<HomeComponent />} />
        <Route path="history" element={<PatientSymptomsHistoryComponent />} />
        <Route path="myinfo" element={<MyInfoComponent />} />
        <Route path="learn" element={<LearnComponent />} />
        <Route path="patientsymptoms" element={<PatientSymptoms />} />
        <Route path="message" element={<MessageComponent />} />
        <Route path="*" element={<>Nothing here</>} />
      </Route>
    </Routes>
  );
};

export default RouteComponent;
