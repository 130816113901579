import { useQuery } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import DeviceDetails from "./../types/DeviceDetails";
import React from "react";
import { Outlet } from "react-router";
import { Q_DEVICE_FROM_USER } from "./../data/queries/device";

const Main = () => {
  const { instance } = useMsal();
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  const { loading, error, data } = useQuery(Q_DEVICE_FROM_USER, {
    variables: { email: activeAccount?.idTokenClaims?.email?.toLowerCase() },
    skip: !activeAccount?.idTokenClaims?.email,
  });
  const [device, setDevice] = React.useState(null);
  React.useEffect(() => {
    if (data) {
      setDevice({
        deviceId: data?.patient?.devices[0]?.deviceId,
        email: activeAccount?.idTokenClaims?.email?.toLowerCase(),
        firstName: activeAccount?.idTokenClaims?.given_name,
        lastName: activeAccount?.idTokenClaims?.family_name,
      });
    }
  }, [data]);
  return (
    <DeviceDetails.Provider value={device}>
      <Outlet />
    </DeviceDetails.Provider>
  );
};

export default Main;
